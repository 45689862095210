import "./pcYear.css";
function Pcyear({ year }) {
  return (
    <>
      <div className="year">
        <h1>ICAIN {year}</h1>
      </div>
      <hr />
    </>
  );
}

export default Pcyear;

export default
 [
  {
    link: require("./sessions/SS-1.pdf"),
    title:
      "Innovations in Computational Electronics and Communication Systems",
    organisers: [
      "Dr. Sangeeta Kumari, Bennett University (Times Group), Greater Noida, U.P., India"
    ],
    listing: "SS 001",
  },
  
  
  
];

import React from "react";
import "./Policy.css";
import Footer from "../Footer/footer";

const Policy = () => {
  return (
    <div>
      <div className="policyPage">
        <div className="policyPageCard">
          <h1>Policy</h1>
          <div className="policyContent">
            <section className="policySection">
              <div>
                <div>
                  <p>
                    The statement outlines our policies for protecting your
                    privacy with relation to the Personal Information we gather
                    and maintain about you on this website as well as the
                    Personal Information you give us while taking part in our
                    campaigns and events.
                  </p>
                  <strong>
                    I. Personal Information we collect and process and how we
                    use it
                  </strong>
                  <br />
                  <strong>1. Personal Information that we process</strong>
                  <p>
                    For the purposes of this privacy statement, "Personal
                    Information" refers to any information that can be used to
                    identify an individual, either alone or in conjunction with
                    other information that ICAIN may have. Generally speaking,
                    you are not required to enter any Personal Information in
                    order to browse our website. Nonetheless, we gather specific
                    data like this:
                  </p>
                  <ul>
                    <li>
                      Information you give us on our website, including the
                      details you enter to register, such as your name, email
                      address, title, company, country, and phone number
                    </li>
                    <li>
                      Details about your computer, including your IP address,
                      demographics, operating system, browser type, and
                      computer's operating system, as well as information
                      gathered through cookies.
                    </li>
                    <li>
                      Cookie related details are provided in the Cookie Policy
                    </li>
                  </ul>
                  <strong>2. Use of your Personal Information</strong>
                  <p>
                    We use your Personal Information for the following purposes:
                  </p>
                  <ul>
                    <li>
                      to provide better usability, troubleshooting and site
                      maintenance;
                    </li>
                    <li>
                      to understand which parts of the website are visited and
                      how frequently;
                    </li>
                    <li>to create your user ID;</li>
                    <li>to identify you once you register on our website;</li>
                    <li>
                      to contact you and respond to your questions or requests;
                      and
                    </li>
                    <li>
                      to provide access to desirable content based on your
                      preferences.
                    </li>
                    <li>
                      to process job application and alumni related requests,
                      more details about which are available on corresponding
                      portals of this website.
                    </li>
                  </ul>
                  <strong>3. Legal basis of the processing</strong>
                  <p>
                    When it comes to carrying out a contract in which you are a
                    party, or when we need to act at your request before signing
                    a contract, we process your personal information. This is
                    applicable in any situation when we serve you in accordance
                    with a contract, as when you visit our website or complete
                    the registration process there. We will not be able to offer
                    you our services if you do not give us the Personal
                    Information that we require. In the following situations, we
                    process your personal information to fulfill a contract:
                  </p>
                  <ul>
                    <li>
                      to provide better usability, troubleshooting and site
                      maintenance; and
                    </li>
                    <li>to create your user ID.</li>
                  </ul>
                  <p>
                    When it is required to further a legitimate interest that we
                    or a third party is pursuing, we process your personal
                    information (as long as your data protection rights do not
                    conflict with these interests). This is applicable in the
                    subsequent situations:
                  </p>
                  <ul>
                    <li>
                      to identify you once you register on our website; and
                    </li>
                    <li>
                      to contact you and respond to your questions or requests;
                    </li>
                    <li>
                      to understand which parts of the website are visited and
                      how frequently; and
                    </li>
                    <li>
                      to provide access to desirable content based on your
                      preferences.
                    </li>
                  </ul>
                  <strong>
                    II. Consequences of not providing Personal Information
                  </strong>
                  <p>
                    If you choose not to provide your Personal Information that
                    is mandatory to process your request, we may not be able to
                    provide the corresponding service.
                  </p>
                  <strong>
                    III. Data recipients, transfer, and disclosure of Personal
                    Information:
                  </strong>
                  <p>
                    ICAIN does not share your Personal Information with third
                    parties for marketing purposes without seeking your prior
                    permission.
                  </p>
                  <p>We share your Personal Information within</p>
                  <ul>
                    <li>ICAIN or with any of its subsidiaries;</li>
                    <li>Business partners;</li>
                    <li>Service vendors;</li>
                    <li>Authorized third-party agents; or</li>
                    <li>Contractors.</li>
                  </ul>
                  <strong>IV. Use of cookies:</strong>
                  <p>Please refer the Cookie Policy</p>
                  <strong>
                    V. Access, correction, objection of your Personal
                    Information:
                  </strong>
                  <p>
                    You are entitled to see, update, remove, or transfer the
                    Personal Information we have about you, including your
                    preferences and profile. Additionally, you have the right to
                    withdraw your consent if we have requested for it before
                    processing your personal information and to object to
                    specific processing. You also have the right to object in
                    cases when we process your Personal Information because we
                    have a legitimate interest in doing so (as previously
                    mentioned). Certain circumstances may result in a limitation
                    of these rights, such as where we can show that processing
                    your personal information is required by law.
                  </p>
                  <strong>VI. Data security</strong>
                  <p>
                    ICAIN adopts reasonable and appropriate security practices
                    and procedures including administrative, physical security,
                    and technical controls in order to safeguard your Personal
                    Information.
                  </p>
                  <strong>VII. Data retention</strong>
                  <p>
                    Personal Information will not be retained for a period more
                    than necessary to fulfill the purposes outlined in this
                    privacy statement, unless a longer retention period is
                    required by law or for directly related legitimate business
                    purposes.
                  </p>
                  <strong>VIII. Linked websites</strong>
                  <p>
                    Our privacy practices regarding Personal Information that we
                    collect and store about you through our portals will be as
                    per the privacy policy of those portals.
                  </p>
                  <p>
                    ICAIN provides links to third-party websites and services.
                    However, ICAIN is not responsible for the privacy
                    statements, practices, or the contents of such third-party
                    websites.
                  </p>
                  <strong>IX. Updates to this privacy statement</strong>
                  <p>
                    ICAIN may change the data privacy practices and update this
                    privacy statement as and when the need arises, and the same
                    will be made available on the website. But our commitment to
                    protect the privacy of website users will continue to
                    remain.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Policy;

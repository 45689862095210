import './PcMainbody.css'

function PcMainBody({myBody}) {
    return ( 
    
    <div className="Pcbody">
       <p>{myBody}</p> 

    </div>
        )
}

export default PcMainBody;
import './HiEduAward.css'
import HiEduData from './HiEduData';
function HiEduAward() {
    return ( 
        <>
        <div className="HieduMain">
            <h2>Higher Education Awards</h2>
            <HiEduData/>
        </div>
        </>
     );
}

export default HiEduAward;
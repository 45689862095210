// import ImpDate from "../impDates/impDates";
// import ImpNotices from "../ImpNotices/impNotices";
import "./HomeDateNotice.css";
import ImpNtext from "../ImpNotices/ImpNtext";
import ImpDtext from "../impDates/impDtext";
import '../RegistrationSection/registration.css'
function HoDateNotice() {
  return (
    
      
     
              <div className="  date_notices ">
     
     <ImpDtext />
     <ImpNtext />
   </div>
    
  
  );
}

export default HoDateNotice;
